.message {
  display: flex;
  justify-content: space-between;
  margin-bottom: 200px;

  .envelope {
    position: relative;
    height: fit-content;
    margin: 10px 10px 0 0;
  }
}
