$primary: #201150;
$secondary: #d546f8;
$tertiary: #ffe34b;
$quaternary: #f79dff;
$blog-gray: #929292;

$background: #fcf2ff;
$background-alt: #f8f2ff;
$soft-blue: #4a4371;
$disabled-background: #e395fb;

$error: #ff2b64;
$alert-chat: #ff2d55;
$background-error: #ffd4e0;

$modal-overlay: rgb(0 0 0 / 15%);

$ai-agent-neutral-0: #fafafa;
$ai-agent-neutral-50: #f3f3f3;
$ai-agent-neutral-100: #e7e7e7;
$ai-agent-neutral-200: #cececf;
$ai-agent-neutral-300: #b6b6b7;
$ai-agent-neutral-400: #858586;
$ai-agent-neutral-500: #545556;
$ai-agent-neutral-600: #323335;
$ai-agent-neutral-700: #232426;
$ai-agent-neutral-800: #1c1d1f;
$ai-agent-neutral-900: #121315;
$ai-agent-neutral-1000: #000;

$ai-agent-teal-50: #e8faf8;
$ai-agent-teal-100: #d0f4f1;
$ai-agent-teal-200: #b9efe9;
$ai-agent-teal-300: #73dfd4;
$ai-agent-teal-400: #45d5c5;
$ai-agent-teal-500: #16cab7;
$ai-agent-teal-600: #12a292;
$ai-agent-teal-700: #0d796e;
$ai-agent-teal-800: #095149;
$ai-agent-teal-900: #042825;

$ai-agent-bg: $ai-agent-neutral-900;
$ai-agent-accent: $ai-agent-teal-300;
$ai-agent-text-color: $ai-agent-neutral-0;
$ai-agent-dark-accent: $ai-agent-teal-600;
