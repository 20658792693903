@import '@styles/theme/theme';

.wrapper {
  width: 100%;
  max-width: 500px;
  height: fit-content;
  padding: 40px 50px;
  margin: 50px auto;
  background-color: $background-alt;
  border-radius: 16px;
  transition: 0.3s;

  h2 {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 300;
    text-align: center;
  }

  @media screen and (width <= 500px) {
    padding: 20px;
    margin-top: 20px;
  }

  input {
    margin: 5px auto;

    @media screen and (width <= 500px) {
      font-size: 18px;
    }
  }

  button {
    margin-top: 10px;
    font-size: 18px;
  }

  p {
    margin: 20px auto;
    text-align: center;

    @media screen and (width <= 500px) {
      font-size: 18px;
    }
  }

  ul {
    margin-bottom: 20px;

    li {
      margin-left: 25px;
      font-size: 18px;
      list-style-type: disc;
    }
  }

  a {
    text-align: center;
    text-decoration: underline;
  }
}
