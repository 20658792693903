.h1 {
  margin-bottom: 20px;
  line-height: 60px;
  text-align: center;

  @media screen and (width <= 500px) {
    font-size: 30px;
    line-height: 40px;
  }
}

.h2 {
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: 400;
  text-align: center;

  @media screen and (width <= 500px) {
    font-size: 18px;
  }
}
